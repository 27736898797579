<template>
	<div class="grid-container">
		<template v-if="courses.length > 0">
			<v-card
				class="grid-item"
				v-for="(item, index) in courses"
				:key="index"
				style="cursor: pointer; position: relative"
				@click="handleClickCourse(item)"
			>
				<div class="backdrop d-flex align-center justify-center">
					<v-icon large color="white">mdi-cast-education</v-icon>
				</div>
				<v-card-title class="d-flex flex-column align-start">
					<div class="title">{{ item.title }}</div>
					<div class="description">
						{{ item.description }}
					</div>
				</v-card-title>
			</v-card>
		</template>
		<template v-else>
			<template v-if="isFetchCourses">
				<div v-for="(n, index) in 6" :key="'skeleton-loader-' + index">
					<v-skeleton-loader type="image, article" />
				</div>
			</template>
			<template v-else>
				<h4>현재 수강 가능한 강의가 없습니다</h4>
			</template>
		</template>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useRouter } from '@core/utils'

import MarketCourseService from '@/services/MarketCourseService'

export default {
	components: {},
	setup() {
		const { router } = useRouter()
		const courses = ref([])
		const isFetchCourses = ref(false)

		const listMarketCourses = async () => {
			try {
				isFetchCourses.value = true
				courses.value = await MarketCourseService.listMyMarketCourses()
			} catch (e) {
				console.error(e)
			} finally {
				isFetchCourses.value = false
			}
		}
		listMarketCourses()

		const handleClickCourse = async item => {
			router.push({
				name: 'mypage-market-course-detail',
				params: { marketCourseId: item.id },
			})
		}

		return {
			courses,
			isFetchCourses,

			handleClickCourse,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-data-table,
.v-data-table__wrapper {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.grid-container {
	display: grid;
	gap: 24px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-auto-rows: 1fr;

	@media screen and (max-width: 1489px) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}

	@media screen and (max-width: 949px) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@media (max-width: 654px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.grid-item {
		min-height: 245px;

		.backdrop {
			height: 110px;
			background-color: rgb(34, 34, 34);
		}

		.v-card__title {
			font-size: 16px;

			.title {
				font-weight: 700;
				line-height: 1.3;
			}

			.description {
				font-size: 15px;
				color: rgb(94, 95, 97);
				line-height: 19.4px;
				margin-bottom: 2px;
			}
		}
	}
}
</style>
