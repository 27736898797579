<template>
	<v-card elevation="0">
		<v-card-title class="px-0 pt-2">
			<v-icon class="mr-2">mdi-book-open-outline</v-icon>
			학습 코스
		</v-card-title>
		<my-page-market-course-list />
	</v-card>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter } from '@core/utils'

import MyPageMarketCourseList from './MyPageMarketCourseList.vue'

export default {
	components: {
		MyPageMarketCourseList,
	},
	setup() {
		const { router } = useRouter()
		const profile = computed(() => store.getters['user/getMeDetail'])

		const isCiVerified = computed(() => {
			return profile.value && !profile.value?.ciVerified
		})

		onMounted(() => {
			if (isCiVerified.value) {
				router.push({ name: 'auth-phone-number' })
			}
		})
	},
}
</script>
